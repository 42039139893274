import React, { Component } from "react";
import eleven from "../Asset/11.JPG";
import home from "../Asset/home.png";
import twent22 from "../Asset/22.JPG";
import thirt33 from "../Asset/33.JPG";
import fourt44 from "../Asset/44.JPG";
import hkc from "../Asset/hkc.jpg";
import mepc from "../Asset/MEPC-269.jpg";
import eusrr from "../Asset/eu-srr.jpg";
import HKCpdf from "../Asset/HKC.pdf";
import MEFCpdf from "../Asset/MEPC-269(68).pdf";
import EUSRRpdf from "../Asset/EU-SRR.pdf";
import "../Asset/css/homePage.css"

class IHMDetail extends Component {
    authService;
    constructor(props) {
        super(props);
    }

    reDirect = () => {
        this.props.history.push('/Safety');
    }

    reDirecthome = () => {
        this.props.history.push('/');
    }
    reDirectSRS = () => {
        this.props.history.push('/SRS');
    }
    reDirectVIS = () => {
        this.props.history.push('/VIS');
    }
    viewMoreAlcovisor = () => {
        this.props.history.push('/Alcovisor');
    }
    viewMoreNavCom = () => {
        this.props.history.push('/NavCom');
    }
    render() {
        return (
            <div className="row" style={{ background: "white" }}>
                <div className="col-sm-12 col-md-12">
                    <div className="container-fluid bordersky is-sticky">
                        <div className="container">
                            <div className="row p-2">
                                <div className="col-12 col-md-2 center-mobile">
                                    <a href="" onClick={() => this.reDirecthome()}>
                                        <img className="logo-image" src={home} id="navbar-logo"></img>
                                    </a>
                                </div>
                                <div className="col-12 col-md-10">
                                    <ul className="navbar-list float-right pt-3 mb-2 pal-0">
                                        <li><a href="" onClick={() => this.reDirecthome()}>Home</a></li>
                                        <li><a href="#">IHM</a></li>
                                        <li><a href="" onClick={() => this.reDirect()}>Safety</a></li>
                                        <li><a href="" onClick={() => this.viewMoreAlcovisor()}>Alcovizor</a></li>
                                        <li><a href="" onClick={() => this.viewMoreNavCom()}>NavCom</a></li>
                                        <li><a href="" onClick={() => this.reDirectSRS()}>SRS</a></li>
                                        <li><a  href="" onClick={() => this.reDirectVIS()}>VIS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid mt-6">
                        <div className="second-wrapper">
                            <div className="container">
                                <div className="row p-3 pt-4">
                                    <div className="col-6" id="content">
                                        <h5>IHM</h5>
                                    </div>

                                    <div className="col-6 text-right" id="Contact">
                                        <a href="https://www.marinemechanics.biz/contact">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container" id="body-content">
                            <div className="row">
                                <div className=" col-sm-12  col-md-6 " >
                                    <h5 className="bar-bg">Inventory of Hazardous Material</h5>

                                    <p className="top-spc">Marine Mechanics has a strong team of DNV-GL trained IHM experts who can handle IHM for both new builds and existing ships in a professional manner, below script will give a brief description of IHM, its importance, process of obtaining and maintaining the IHM for your ships.</p>
                                    <p className="pb-4">Inventory of Hazardous Materials (IHM) is a structured system to control hazardous materials onboard ships and achieve compliance with the EU Ship Recycling Regulation (EU SRR) and Hong Kong Convention (HKC) for the Safe and Environmentally Sound Recycling of Ships.</p>

                                    <p className="">From December 31, 2020, any ship which is 500 GT or over, regardless of flag, will require a valid and certified IHM on board if calling at an EU port or anchorage. Non-EU flagged vessels can also be certified against EU SRR by complying with the HKC IHM requirements.  </p>
                                    <p className="pb-4">IHM is a list that provides ship-specific information on the actual hazardous materials present on board, their location, and approximate quantities. The IHM has following parts:</p>

                                    <ul className="last-ul" >
                                        <li>Materials contained in ship structure or equipment</li>
                                        <li>Operationally generated wastes, and</li><br></br>
                                        <li>Stores</li>
                                    </ul>

                                </div>
                                <div className="col-sm-12  col-md-6  mt-4" ><img src={eleven} alt="" className="mt-5 ml-5 float-right img-border img-fluid" /> </div>
                            </div>

                            <div className="row mt-3 ">
                                <div className="col-12" >
                                    <p className="pb-4">
                                        The IHM Part I shall be prepared and certified for new ships and ships in operation and shall be maintained and kept up to date during the operational life of the ship, while the IHM Part II & III are only required to be prepared when the ship is decided to be sent for recycling. For the preparation of IHM Part I, hazardous materials set out in appendix 1 and 2 of the HKC or Annex I and II of the EU SRR shall be investigated.
				</p>
                                    <p className="pb-4">
                                        As per EU SRR guidelines, the IHM maintenance may be maintained by a HazMat expert company, such as Marine Mechanics, we have the experience and ability to undertake a comprehensive check and can provide reliable, independent results. Should asbestos be found during IHM maintenance, engaging a specialist maritime asbestos consultant is key to ensuring you make the correct choices regarding removal and management of asbestos on your vessel. We will also always keep the IHM Part 1 up to date ready for inspection from flag state or className.
				</p>
                                </div>

                            </div>
                            <div className="row mt-5">
                                <div className="col-sm-12  col-md-6" ><img src={twent22} alt="" className="img-border img-fluid" width="540" /> </div>
                                <div className="col-sm-12  col-md-6" >
                                    <h5 className="bar-bg">Why is IHM Important?</h5>

                                    <p className="top-spc pb-4">From the mid-1980s, the center of the ship scrapping industry shifted towards Asia, to India, Bangladesh, and Pakistan. This industry was, and still is, virtually unregulated and has one of the worst safety records of any industry. It also causes massive environmental pollution. Initially, there was little awareness or acknowledgement of the appalling working conditions and environmental pollution. This guidance subsequently fed into discussions at the International Maritime Organization (IMO), which resulted in the IMO Guidelines on Ship Recycling, adopted by member states in December 2003. These voluntary guidelines introduced the concept of a ‘Green Passport’ Inventory for the first time. In parallel with this activity, the International Labor Organization (ILO) developed Guidelines on Safety and Health in Ship Breaking in Asia and Turkey and the Basel Convention published guidance on Environmentally Sound Management of Dismantling of Ships. </p>

                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-12" >
                                    <p className="pb-4">Further, IMO adopted “The Hong Kong International Convention for the safe of environmentally sound recycling of ships” in the year 2009 followed by the EU Ship Recycling Regulation (EU SRR) in 2013 that requires every ship calling at EU Port to carry an inventory of hazardous material (IHM) verified by the relevant administration or authority and specifying the location and approximate quantities of those materials.</p>

                                    <p className="pb-4">A comprehensively compiled IHM can help you minimize risk, potential liabilities, and enhance the safety of your ships’ crews by identifying, recording, and controlling hazardous materials onboard your ships, in line with existing and forthcoming legislation. </p>
                                    <p className="pb-4">As well as assisting with your in-house management systems, you can demonstrate your commitment to safe and environmentally sound practices at the end of your ship’s life.</p>

                                </div>

                            </div>
                            <div className="row mt-5">
                                <div className="col-sm-12  col-md-6" >
                                    <h5 className="bar-bg">Initial IHM Survey & Part 1 Preparation</h5>

                                    <p className="top-spc pb-4">The survey can take place while the vessel is at sea or in port and our surveying teams / HazMat experts are able to meet and leave the vessel at most ports internationally.The IHM should undergo annual verification to ensure its relevance and a full re-survey of the vessel carried out every five years.</p>
                                    <ul className="last-ul">
                                        <li>Collect necessary documents from vessel / owner</li>
                                        <li>Visual Sampling Check Plan (VSCP) - assessment of vessel information.</li>
                                        <li>Onboard verification and sampling survey - validates the information within the VSCP and with verification through sampling</li>
                                        <li>Sample Analysis</li>
                                        <li>Interpretation of analysis results and IHM Report delivery (in Classification specific format, where required)</li>
                                    </ul>

                                </div>
                                <div className="col-sm-12  col-md-6 " ><img src={thirt33} alt="" width="540" className=" ml-5 mt-2 float-right img-border img-fluid" /> </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-12" >

                                    <p className="pb-4 ">Hazards that are commonly found on vessels include Asbestos, polychlorinated biphenyls (PCBs), Organotin Compounds, including TBTs and microbiological contaminants, Ozone Depleting Substances (ODS), Heavy Metals, Polybrominated biphenyl (PBBs), Polybrominated diphenyl ethers (PBDEs), Polychlorinated naphthalene’s (PCN - more than 3 chlorine atoms), Radioactive substances, Certain short chain chlorinated paraffins (SCCP - Alkanes, C10-C13), Perfluorooctanesulfonic Acid (PFOS) and Hexabromocyclododecane (HBCDD). </p>

                                </div>

                            </div>
                            <div className="row mt-5">
                                <div className="col-sm-12  col-md-6" ><img src={fourt44} alt="" width="540" className="img-border img-fluid" /> </div>
                                <div className="col-sm-12  col-md-6" >  <h5 className=" bar-bg">IHM Maintenance post Initial Survey and issuance of SOC</h5>
                                    <p className="pb-4">The EU SRR requires proper maintenance and update of the IHM throughout the operational life of ships for which the Regulation applies. This means that if any new or different machinery or equipment is added to, removed, or replaced, the IHM must be updated by collection of suppliers' declarations. </p>
                                    <p className="pb-4">Maintaining compliance for the entire service life of a vessel does not happen by itself. Any change on board must be notified and an updated IHM-report is required every time something changes. For example: after a refit or intensive docking, it is possible that hazardous materials have been brought onboard. Our IHM Maintenance service simplifies the update process, prevents unnecessary re-sampling, and reduces costs. Completing an IHM survey and possessing an approved report, is only the beginning of being compliant. It is the ship owner's responsibility to ensure continuous conformity of the inventory. </p>

                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-12" >
                                    <p className="pb-4"> Marine Mechanics digital maintenance system makes it easier to maintain your IHM report during the full life cycle of the ship. Why this is so convenient? We simplify work, prevent unnecessary re-sampling, and reduce costs with our IHM Maintenance Service.</p>
                                    <p className="pb-4"> It should be noted that the IHM should be updated according to the requirements for new ships as stipulated in the relevant provisions of the IMO guidelines and in chapter 5.1 of this guidance. The respective changes to the IHM should be made accordingly and all the relevant documentation (e.g., MD and SDoC in case of machinery or equipment is added or sampling reports in case of random sampling) should be collected and maintained in the ship’s archive.</p>
                                    <p className="pb-4"> MMPL IHM Maintenance software can be used to support the IHM development and maintenance process and the management of all the relevant documents, information, and data.After the preparation of the IHM, and its certification by className, our team can take care of the maintenance of the IHM with a digital solution to ensure it is constantly up-to-date and to facilitate the renewal survey after 5 years.  </p>
                                    <ul className="last-ul">
                                        <li>If you have a purchasing system, your purchase information is automatically transferred in the background through simple API’s without further work. Alternatively, we also support you in importing other formats and input options. </li>
                                        <li>The system controls and automates all relevant procedures, as well as documentation for each area of the ship. We automatically archive in your interest and thereby reduce the workload, but not the completeness, for all involved. Also missing information such as SDOCS and Material Declarations are automatically requested and collected by us from your suppliers. </li>
                                        <li>Our system is tailored to the needs of every ship owner and is even usable onboard, from manual to fully automated enterprises. Once an IHM report is created (independent of the earlier chosen supplier), it is uploaded to the database where it can then be updated automatically at any time. This also optimizes your purchasing process and can prevent hazardous materials from being installed at all. </li>
                                    </ul>
                                    <p className="pb-4 pt-4">With internationally recognized laboratories, and a specialist team of hazardous materials experts, analysts, and consultants strategically located across the globe, our  team delivers trusted consultancy, surveying, testing and management for asbestos and other hazardous material management to fleets and vessels throughout the world. </p>


                                </div>

                            </div>
                            <div className="row mt-3 ">
                                <div className="col-12" >

                                    <h5 className=" bar-bg">References </h5>
                                    <p className="pb-4 pt-4"> The Hong Kong International Convention for the Safe and Environmentally Sound Recycling of ships
                                    <br></br><a href={HKCpdf} target="_blank"><img src={hkc} alt="" className="pt-2" /> </a>
                                    </p>

                                    <p className="pb-4">Resolution MEPC269(68) – 2015 Guidelines for the Development of the Inventory of Hazardous MaterialDeclaration
                                    <br></br><a href={MEFCpdf} target="_blank"><img src={mepc} alt="" className="pt-2" /> </a>
                                    </p>
                                    <p className="pb-4">COMMISSION REGULATION 1257/2013 - EU Regulation on Ship Recycling (SRR) Regulation (EU) and amending Regulation (EC) No 1013/2006 and Directive 2009/16/EC
                                    <br></br><a href={EUSRRpdf} target="_blank"><img src={eusrr} alt="" className="pt-2" /> </a>
                                    </p>
                                </div>

                            </div>



                        </div>
                        <div className="footer p-3" id="footer-cont">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 copytext" style={{ textAlign: "center" }}>
                                    Copyright &copy; 2024 Marine Mechanics Pvt Ltd All Rights Reserved.
                                        </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default IHMDetail;
