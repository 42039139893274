const baseUrl = process.env.REACT_APP_API_BASE_URL;
const API = {
    MDSearch: `${baseUrl}/api/materialdeclarations`,
    MDDetail: `${baseUrl}/api/materialdeclaration`,
    MDMaterialInformation: `${baseUrl}/api/MaterialDeclaration/mdmaterials`,
    MDRelatedDocuments: `${baseUrl}/api/materialdeclaration/mdrelateddocuments/`,
    MasterMaterialInformation: `${baseUrl}/api/materialdeclaration/mastermaterials`,
    MDRemarksGet: `${baseUrl}/api/materialdeclaration/remarks`,
    CompanyList: `${baseUrl}/api/SMD/Companies`,

    //MD New Item Post method API'S
    MDPostAPI: `${baseUrl}/api/materialdeclaration`,
    MIPostAPI: `${baseUrl}/api/materialdeclaration/mdmaterials`,
    RDPostAPI: `${baseUrl}/api/materialdeclaration/mdrelateddocuments`,
    RemarkPostAPI: `${baseUrl}/api/materialdeclaration/mdremarks`,

    //MD Delete
    MDDelete: `${baseUrl}/api/materialdeclaration`,

    //MD item update

    MDUpdateAPI: `${baseUrl}/api/materialdeclaration`,
    MIUpdateAPI: `${baseUrl}/api/materialdeclaration/mdmaterials`,
    RDUpdateAPI: `${baseUrl}/api/materialdeclaration/mdrelateddocuments`,

    //SMD Search

    SMDSearch: `${baseUrl}/api/suppliermaterialdeclarations`,
    SMDPostAPI: `${baseUrl}/api/suppliermaterialdeclaration`,


    //attachment upload

    AttachmentAPI: `${baseUrl}/api/attachment/upload`,
    DownloadAttachmentAPI: `${baseUrl}/api/attachment/download`,


    //SMD delete

    SMDDelete: `${baseUrl}/api/suppliermaterialdeclaration/`,

    //reports API

    ReportAPI: `${baseUrl}/api/reports`,
    ExportReportAPI: `${baseUrl}/api/report/download`,

    //Users API
    UserAPI: `${baseUrl}/api/user`,
    getUser: `${baseUrl}/api/users`,

    //login user Authentication

    AuthenticateUser: `${baseUrl}/api/login`,
    ADUsers: `${baseUrl}/api/adusers`,

    // Ship View
    ShipDetail: `${baseUrl}/api/ship`,
    PendingTask: `${baseUrl}/api/ship/pendingtasks`,
    PendingTaskLineItem: `${baseUrl}/api/ship/pendingtasks/polineItems`,
    IHMFullManual: `${baseUrl}/api/ship/ihmpart`,
    RevisionHistory: `${baseUrl}/api/ship/revisionhistory`,
    Certificates: `${baseUrl}/api/ship/certificate`,
    POSearch: `${baseUrl}/api/ship/validatedposearch`,
    putPendingTask: `${baseUrl}/api/ship/pendingtask`,
    bulkUpdatePendingTask: `${baseUrl}/api/ship/bulkpendingtasks`,

    // Client Dashboard
    ClientShipCount: `${baseUrl}/api/totalships`,
    ClientShipSearch: `${baseUrl}/api/shipsearch`,

    //MMPl Dashboard
    MMPLShipCount: `${baseUrl}/api/mmpl/totalships`,
    MMPLShipSearch: `${baseUrl}/api/mmpl/posearch`,
    CollectMDocSDocAPI: `${baseUrl}/api/mmpl/podoccollect`,
    GetInventory: `${baseUrl}/api/inventory`,
    PostInventory: `${baseUrl}/api/inventory`,
    PutInventory: `${baseUrl}/api/inventory`,
    GetPaintCoating: `${baseUrl}/api/inventory/paint`,
    GetEquipmentMachinery: `${baseUrl}/api/inventory/equipment`,
    GetStructureHull: `${baseUrl}/api/inventory/structure`,
    GetStore: `${baseUrl}/api/inventory/stores`,

    //PO View
    PODetail: `${baseUrl}/api/mmpl/po`,
    LineItems: `${baseUrl}/api/ship/polineitems`,
    PoConfirmation: `${baseUrl}/api/mmpl/poconfirmation`,
    PoConfirmationAll: `${baseUrl}/api/mmpl/poconfirmation/all`,
    CollectMDLineItems: `${baseUrl}/api/mmpl/collectmd/lineitems`,
    AddAll: `${baseUrl}/api/mmpl/po/lineitem/add/povalidation`,

    //Exception management 
    Exception: `${baseUrl}/api/manage/exception`,

    //Keyword management 
    Keywordlist: `${baseUrl}/api/manage/keywords`,
    postKeyword: `${baseUrl}/api/manage/keyword`,

    //Material declaration
    GetMaterialDeclaration: `${baseUrl}/api/materialdeclaration/v2`,
    PostMaterialDeclaration: `${baseUrl}/api/materialdeclaration/v2`,
    BulkUpdateMDSDoc: `${baseUrl}/api/materialdeclaration/v2/All`,
    PutMaterialDeclaration: `${baseUrl}/api/materialdeclaration/v2`,
    DeleteMaterialDeclaration: `${baseUrl}/api/materialdeclaration/v2`,

    //Staff management 
    Stafflist: `${baseUrl}/api/manage/staffs`,
    PostStaff: `${baseUrl}/api/manage/staff`,

    //Client management 
    Clientlist: `${baseUrl}/api/manage/client`,
    postClient: `${baseUrl}/api/manage/clients`,
    ClientUser: `${baseUrl}/api/manage/clientuser`,

    //Ship management 
    Shiplist: `${baseUrl}/api/manage/ships`,
    postShip: `${baseUrl}/api/manage/ship`,

    //helpers
    shipNames: `${baseUrl}/api/ship/names`,
    companyNames: `${baseUrl}/api/clientlist`,
    companyUserNames: `${baseUrl}/api/clientuserslist`,

    //po export

    POExport: `${baseUrl}/api/PO/Export`,
    POExportExcel: `${baseUrl}/api/PO/Export/Excel`,

    //Six month review
    GetSixMonthReview: `${baseUrl}/api/Ship/Review`,
    GetSixMonthReviews: `${baseUrl}/api/Ship/Reviews`,
    PostSixMonthReview: `${baseUrl}/api/Ship/Review`,

    //Exist user check
    userExist:`${baseUrl}/api/User/Exist`,

    // IdentityServer
    password:`${baseUrl}/api/ChangePassword`,

    // Inventory
    UploadRevision:`${baseUrl}/api/mannual/attachment`,

}
export default API;