import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Signin, RedirectCallback, LogoutCallback } from "../Authentication/Callbacks";
import HomePage from "../components/HomePage";
import IHMDetail  from "../components/IHMDetail";
import Safety  from "../components/Safety"
import SRS from "../components/SRSDetail";
import VIS from "../components/VISDetail";
import Alcovisor from "../components/Alcovisor";
import NavCom from "../components/NavCom";

function Routes() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact={true} path="/" component={HomePage} />
                    <Route exact={true} path="/IHM" component={IHMDetail} />
                    <Route exact={true} path="/Safety" component={Safety} />
                    <Route exact={true} path="/SRS" component={SRS} />
                    <Route exact={true} path="/VIS" component={VIS}/>
                    <Route exact={true} path="/Alcovisor" component={Alcovisor}/>
                    <Route exact={true} path="/NavCom" component={NavCom}/>
                    <Route exact={true} path="/login" component={Signin} />
                    <Route exact={true} path="/signin" component={RedirectCallback} />
                    <Route exact={true} path="/logout" component={LogoutCallback} />
                </Switch>
            </div>
        </Router>
    );
}

export default Routes;
