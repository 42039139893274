import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthProvider } from './Authentication/AuthProvider';
import Routes from './router/routes';
import { connect } from "react-redux";

function App(props) {
  return (
    <>
      <div className="full-height" style={{ width: "100%"}}>
        <AuthProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </AuthProvider>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    // showSidePane: state.SidePanReducer.sidePan || false,
    // SubBannerTitle: state.SubBannerrTitle || 'MD Library',
    // ShowMISidePan: state.ShowMISidePan || false,
    // ShowRDSidePan: state.ShowRDSidePan || false,
    // ShowEURegSidePan: state.ShowEURegSidePan || false,
    // isAttachment: (state.SidePanReducer.isAttachment) ? state.SidePanReducer.isAttachment : false,
    // IsSMDEdit: state.SidePanReducer.IsSMDEdit,
    // isAuthenticated: state.SidePanReducer.isAuthenticated,
    // editPOView: state.SidePanReducer.EditPOView || false,
    // showPODetail: state.SidePanReducer.ShowPODetail || false,
    // editPendingTaskPresence: state.SidePanReducer.EditPendingTaskPresence || false,
    // editPendingTaskRevision: state.SidePanReducer.EditPendingTaskRevision || false,
  }
}

export default connect(mapStateToProps, null)(App);
