import React, { Component } from "react";
import navcom from "../Asset/rbs.png";
import home from "../Asset/home.png";
import "../Asset/css/homePage.css"

class NavCom extends Component {
    authService;
    constructor(props) {
        super(props);
    }

    reDirect = () => {
        this.props.history.push('/Safety');
    }
    reDirectIHM = () => {
        this.props.history.push('/IHM');
    }

    reDirecthome = () => {
        this.props.history.push('/');
    }
    reDirectSRS = () => {
        this.props.history.push('/SRS');
    }
    reDirectVIS = () => {
        this.props.history.push('/VIS');
    }
    viewMoreAlcovisor = () => {
        this.props.history.push('/Alcovisor');
    }

    render() {
        return (
            <div className="row" style={{ background: "white" }}>
                <div className="col-sm-12 col-md-12">
                    <div className="container-fluid bordersky is-sticky">
                        <div className="container">
                            <div className="row p-2">
                                <div className="col-12 col-md-2 center-mobile">
                                    <a href="" onClick={() => this.reDirecthome()}>
                                        <img className="logo-image" src={home} id="navbar-logo"></img>
                                    </a>
                                </div>
                                <div className="col-12 col-md-10">
                                    <ul className="navbar-list float-right pt-3 mb-2 pal-0">
                                        <li><a href="" onClick={() => this.reDirecthome()}>Home</a></li>
                                        <li><a href="" onClick={() => this.reDirectIHM()}>IHM</a></li>
                                        <li><a href="" onClick={() => this.reDirect()}>Safety</a></li>
                                        <li><a href="" onClick={() => this.viewMoreAlcovisor()}>Alcovizor</a></li>
                                        <li><a href="#">NavCom</a></li>
                                        <li><a href="" onClick={() => this.reDirectSRS()}>SRS</a></li>
                                        <li><a href="" onClick={() => this.reDirectVIS()}>VIS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid mt-6">
                        <div className="second-wrapper">
                            <div className="container">
                                <div className="row p-3 pt-4">
                                    <div className="col-6" id="content">
                                        <h5>NavCom</h5>
                                    </div>

                                    <div className="col-6 text-right" id="Contact">
                                        <a href="https://www.marinemechanics.biz/contact">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container" id="body-content">
                            <div className="row">
                                <div className=" col-sm-12" >
                                    <h5 className="bar-bg">Navigation & Communication Services</h5>

                                    <p className="top-spc">Marine Mechanics was incorporated in 2013 with a clear vision to provide one stop solution for all issues related to Navigation, Automation and Communication equipment installed onboard vessels, we currently operate from India, Singapore, UAE & Houston, USA.</p>
                                    <p className="">Our main aim is to give complete Navcom solution to ship’s owners & management companies right from troubleshooting, fault finding, root cause analysis of issue to supplying spare parts and finally fixing the issue by arranging service attendance globally if required.</p>

                                    <p className="">We also offer 24×7 remote support through our Marine Call Center. Our skilled engineers, coordinators & surveyors are available for any repair, maintenance job or class survey for your navigation or communication equipment. With years of experience in the field of navigation equipment as an independent supplier it has enabled us to supply the best equipment from the best manufacturers.</p>

                                </div>
                            </div>
                            <div className="row mt-4 mb-5">
                                <div className="col-12" >
                                    <h5 className="bar-bg">Bridge Equipments We Handle</h5>
                                    <div className="img-container mt-4">
                                        <img src={navcom} alt="" className="img-border img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer p-3" id="footer-cont">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 copytext" style={{ textAlign: "center" }}>
                                    Copyright &copy; 2024 Marine Mechanics Pvt Ltd All Rights Reserved.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default NavCom;