import React, { Component } from "react";
import Shipimage from "../Asset/Digitaltool.jpg";
import home from "../Asset/home.png";
import "../Asset/css/homePage.css";

class SRSDetail extends Component {
    authService;
    constructor(props) {
        super(props);
    }

    reDirectSafety = () => {
        this.props.history.push('/Safety');
    }
    reDirect = () => {
        this.props.history.push('/IHM');
    }

    reDirecthome = () => {
        this.props.history.push('/');
    }
    reDirectVIS = () => {
        this.props.history.push('/VIS');
    }
    viewMoreAlcovisor = () => {
        this.props.history.push('/Alcovisor');
    }
    viewMoreNavCom = () => {
        this.props.history.push('/NavCom');
    }

    render() {
        return (
            <div className="row" style={{ background: "white" }}>
                <div className="col-sm-12 col-md-12">
                    <div className="container-fluid bordersky is-sticky">
                        <div className="container">
                            <div className="row p-2">
                                <div className="col-12 col-md-2 center-mobile">
                                    <a href="" onClick={() => this.reDirecthome()}>
                                        <img className="logo-image" src={home} id="navbar-logo"></img>
                                    </a>
                                </div>
                                <div className="col-12 col-md-10">
                                    <ul className="navbar-list float-right pt-3 mb-2 pal-0">
                                        <li><a href="" onClick={() => this.reDirecthome()}>Home</a></li>
                                        <li><a href="" onClick={() => this.reDirect()}>IHM</a></li>
                                        <li><a href="" onClick={() => this.reDirectSafety()}>Safety</a></li>
                                        <li><a href="" onClick={() => this.viewMoreAlcovisor()}>Alcovizor</a></li>
                                        <li><a href="" onClick={() => this.viewMoreNavCom()}>NavCom</a></li>
                                        <li><a href="#">SRS</a></li>
                                        <li><a  href="" onClick={() => this.reDirectVIS()}>VIS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid mt-6 mb-4">
                        <div className="second-wrapper">
                            <div className="container">
                                <div className="row p-3 pt-4">
                                    <div className="col-6" id="content">
                                        <h5>SRS</h5>
                                    </div>

                                    <div className="col-6 text-right" id="Contact">
                                        <a href="https://www.marinemechanics.biz/contact">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container" id="body-content">
                             <div className="row">
                                 <div className="col-sm-12  col-md-6">
                                       <h5 className="bar-bg">Sustainable Ship Recycling</h5>
                                          <p className="top-spc">Ship Recycling is the most appropriate term, and it is much broader as it implies an element of “Sustainability “. Ship recycling is the process of ship dismantling using safe and environmentally sound procedures and using the materials and equipment for reuse. Steel scrap is used for secondary steel making.</p>
                                          <p className="pt-3 pb-4">Marine Mechanics can assist ship owners dispose of vessels which have outlived their commercial usefulness. These vessels are sold to cash buyers, brokers, yards for recycling as per the norms laid out by the Hong Kong Convention/EU SRR.</p>
                                           <p className=""> We ensure adequate supervision is provided at the shipyard to ensure that the ship recycling plan is followed via a digital platform to ensure ship recycling transparency amongst various stakeholders.</p>
                                 </div>
                                <div className="col-sm-12  col-md-6  mt-4" ><img src={Shipimage} alt="" className="mt-5 ml-5 float-right img-border img-fluid" /> </div>
                                  </div>
                                 
                             <div className="row mt-5">
                                 <div className="col-12">
                                     <h5 className="bar-bg">Our Services</h5>
                                           <p className="top-spc">Selection of Ship Recycling Yards by conducting a due diligence audit according to the preferred compliance of ship owners (EUSRR/HKC)</p>
                                           <p className="">The due diligence audit will be conducted in accordance with EUSRR, HKC and other applicable standards, in accordance with shipowners' requirements to check the documentations, on-site inspections to assist Ship Owners and Recycling facilities in reaching an agreement on Sustainable Ship Recycling Standards.</p>
                                           <p className="">Assist Ship Owners in the Initial/Final Survey to obtain the IRRC, if required by the Ship Owners as part of the IHM verification and review the Ship-specific recycling plan and aid Ship Owners and Recycling facility to comply with the standards.</p>
                                           <p className="">We are Class authorized service providers of IHM solutions. Our team of HazMat experts offer one stop solution right from identification, preparation to certification of IHM. We also have a web based IHM Maintenance portal that enables vessels to be IHM compliant throughout her life cycle as per HKC & EU SRR guidelines.</p>
                                           <p className="">The ship recycling plan shall be prepared by the yard as per MEPC 196 (62) / EUSRR, in accordance with standards agreed by the Ship Owner and the Recycling facility.</p>
                                          <p className="">Pre-beaching process verification and monitoring at Anchorage on behalf of Ship Owners</p>
                                          <p className="">On arrival at Anchorage MMPL expert shall board the vessel on behalf of Ship Owners to ensure all the relevant checks and clearance by the state government and Monitoring of the Ship Recycling Facility on the preparation of the Beaching process.</p>
                                          <p className="">On-site Sustainable Recycling Monitoring and assistance to the Yard with respect to compliance on behalf of Ship Owners and Digital Reporting (Daily/ Weekly/ Monthly and Investigations of Near Miss and Incidents)</p>
                                           <p className="">The MMPL Representative present at the Recycling Facility shall ensure all operations are performed according to the agreed requirements and prepare the reports (Daily/Weekly/Monthly) in our Digital platform, and timely disseminate to the Ship Owners, Conduct investigations of near misses and incidents and report to the Ship Owners.</p>
                                           <p className="">Final Completion Report and Downstream Waste Management Verifications</p>
                                          <p className="pb-4">Upon completion of the Ship Recycling, the relevant manifests and the SoC shall be obtained from the competent authorities by the recycling facility, and we verify downstream waste management process to submit the final completion report to Ship Owners.</p>  
                                </div>
                            </div>
                           
                                       
                           
                             <div className="row mt-3">
                                <div className="col-12" >
                                     <h5 className="bar-bg mb-4">Our Digital Product</h5>
                                     <ul className="last-ul">
                                         <li>Our digital product includes the following features to assist the shipowners and capital providers ESG compliance.</li>
                                         <li>Domestic and international regulations for the ship recycling are complied with.</li>
                                         <li>Ship owner can select the appropriate recycling facility as per the size and type of the vessel.</li>
                                         <li>Existing Inventory of Hazardous Material is evaluated, and gaps are found. If IHM is missing, then a new IHM is prepared.</li>
                                         <li>Shipowner will be able to monitor the entire process of the recycling regularly (Daily / Weekly / Monthly) and get an intermediate and completion reports.</li>
                                         <li>The quantities of steel, equipment and machinery recovered from the ship is informed to the ship owner</li>
                                         <li>The workers involved in the recycling process get the adequate training, safe working atmosphere. All social aspects are covered.</li>
                                        <li className="mb-4">Shipowner will be able to do the real time monitoring of the ship recycling process until completion.</li>
                                </ul>
                                </div>
                             </div>
                        </div>
                        </div> 
                     <div className="footer p-3" id="footer-cont">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 copytext" style={{ textAlign: "center" }}>
                                    Copyright &copy; 2024 Marine Mechanics Pvt Ltd All Rights Reserved.
                            </div>
                        </div>
                    </div>

                </div>
             </div>
            
        );
    }
}

export default SRSDetail;
