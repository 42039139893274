import React from 'react';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './Reducers/index';

const middleWare = [thunkMiddleware, logger];

function ConfigureStore(initialState) {
    return createStore(rootReducer, initialState, applyMiddleware(...middleWare));
}

const store = ConfigureStore();
export default store;