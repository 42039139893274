import React from "react";
// import spinner from 'react-bootstrap/Spinner';
// import logo from '../Asset/logo.png';
import spinner from '../Asset/loading.svg'

export const CustomSpinner = () => {
  return (
    // <div className="mt-5">
    //   <Spinner animation="border" role="status">
    //     <span className="sr-only">Loading...</span>
    //   </Spinner>
    // </div>
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div className="loading">
          <img src={spinner} alt="loading..." width="100" /><span><h3>Loading...</h3></span>
        </div>
      </header>
    </div>
  );
}

export default CustomSpinner;